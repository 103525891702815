@import url('https://fonts.googleapis.com/css?family=Poppins:400,600&display=swap');

p, span, a, ul, li, label, small, h1, h2, h3, h4, h5, h6, input{
  font-family: "Poppins";
}
body{
  background-color: #f5f5f5;
}

/* PISANDO ESTILOS DE ANTD DE COSAS A LAS QUE SE LLAMA PROGRAMÁTICAMENTE */

.ant-message-notice-content{
  border-radius: 8px;
}

.ant-modal-content{
  border-radius: 8px;
}

.ant-modal-confirm-btns button, .ant-modal-footer button{
  border-radius: 6px;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.2);
  border: none;
  opacity: 1;
  transition: all 200ms ease-in;
}

.ant-modal-confirm-btns button:hover, .ant-modal-footer button:hover, .ant-modal-confirm-btns button:focus, .ant-modal-footer button:focus{
    opacity: 0.8;
    color: #232323;
    background: #f3f3f3;
}

.ant-modal-confirm-btns button.ant-btn-primary, .ant-modal-confirm-btns button.ant-btn-primary:hover, .ant-modal-footer button.ant-btn-primary,.ant-modal-footer button.ant-btn-primary:hover {
  background: linear-gradient(135deg, rgba(80,150,127,1) 0%, rgba(40,126,109,1) 100%);
  color: white;
}

.ant-modal-footer{
  border: none;
}

.ant-picker-cell-selected .ant-picker-cell-inner{
  background: linear-gradient(135deg, rgba(80,150,127,1) 0%, rgba(40,126,109,1) 100%) !important;
  border-radius: 6px;
  color: white;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled){
  background-color: rgba(19, 177, 91, 0.15) !important;
}

.ant-spin-dot-item{
  background-color: rgb(70, 206, 178) !important;
}

.ant-select-selection-item{
  border-radius: 10px !important;
}
