.objCardContainer {
  height: 108px;
  width: 282px;
  border-radius: 7px;
  background-color: #ffffff;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.12);
}

.titleObj {
  height: 23px;
  width: 96px;
  color: #545454;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.32px;
  line-height: 23px;
  text-align: center;
  margin-left: 10px;
  margin-top: 5px;
}

.resultadoCol {
  height: 28px;
  max-width: 60px;
  border-radius: 4px;
  background: linear-gradient(180deg, #b4ec51 0%, #429321 100%);
  padding: 5px;
}

.resultado {
  height: 20px;
  width: 48px;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: -0.89px;
  line-height: 20px;
}

.objConseguido {
  height: 40px;
  width: 124px;
  color: #3c3c3c;
  font-family: "Poppins", sans-serif;
  font-size: 29px;
  font-weight: 600;
  letter-spacing: -1.71px;
  line-height: 40px;
  margin-left: 10px;
}

.objtext {
  height: 15px;
  width: 37px;
  color: #545454;
  font-family: "Poppins", sans-serif;
  font-size: 9px;
  font-weight: 500;
  letter-spacing: -0.2px;
  line-height: 15px;
  text-align: center;
  margin-left: 60px;
}

.objeur {
  height: 23px;
  width: 73px;
  color: #b7b7b7;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 23px;
}

.progress {
  margin: 10px;
}
