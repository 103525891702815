@import url(https://fonts.googleapis.com/css?family=Poppins:400,600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p, span, a, ul, li, label, small, h1, h2, h3, h4, h5, h6, input{
  font-family: "Poppins";
}
body{
  background-color: #f5f5f5;
}

/* PISANDO ESTILOS DE ANTD DE COSAS A LAS QUE SE LLAMA PROGRAMÁTICAMENTE */

.ant-message-notice-content{
  border-radius: 8px;
}

.ant-modal-content{
  border-radius: 8px;
}

.ant-modal-confirm-btns button, .ant-modal-footer button{
  border-radius: 6px;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.2);
  border: none;
  opacity: 1;
  transition: all 200ms ease-in;
}

.ant-modal-confirm-btns button:hover, .ant-modal-footer button:hover, .ant-modal-confirm-btns button:focus, .ant-modal-footer button:focus{
    opacity: 0.8;
    color: #232323;
    background: #f3f3f3;
}

.ant-modal-confirm-btns button.ant-btn-primary, .ant-modal-confirm-btns button.ant-btn-primary:hover, .ant-modal-footer button.ant-btn-primary,.ant-modal-footer button.ant-btn-primary:hover {
  background: linear-gradient(135deg, rgba(80,150,127,1) 0%, rgba(40,126,109,1) 100%);
  color: white;
}

.ant-modal-footer{
  border: none;
}

.ant-picker-cell-selected .ant-picker-cell-inner{
  background: linear-gradient(135deg, rgba(80,150,127,1) 0%, rgba(40,126,109,1) 100%) !important;
  border-radius: 6px;
  color: white;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled){
  background-color: rgba(19, 177, 91, 0.15) !important;
}

.ant-spin-dot-item{
  background-color: rgb(70, 206, 178) !important;
}

.ant-select-selection-item{
  border-radius: 10px !important;
}

.subCard {
    height: 61px;	
    width: 309px;	
    border-radius: 7px;	
    background-color: #FFFFFF;	
    box-shadow: 0 3px 10px 0 rgba(0,0,0,0.12);
    display: flex;
    justify-content: space-between;
}

.subtext {
    height: 26px;	
    width: 81px;	
    color: #545454;	
    font-family: 'Poppins', sans-serif; 	
    font-size: 16px;	
    font-weight: 500;	
    letter-spacing: -0.36px;	
    line-height: 26px;	
    text-align: center;
}

.sub2 {
    height: 61px;	
    width: 80px;	
    border-radius: 4px;	
    background: linear-gradient(90deg, #C0D9B2 0%, #04685C 100%);
}

.subAmount {
    width: 61px;	
    color: #FFFFFF;	
    font-family: 'Poppins', sans-serif; 	
    font-size: 19px;	
    font-weight: 600;	
    letter-spacing: 0.5px;	
    line-height: 26px;
    margin-left: 30px;
}
.objCardContainer {
  height: 108px;
  width: 282px;
  border-radius: 7px;
  background-color: #ffffff;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.12);
}

.titleObj {
  height: 23px;
  width: 96px;
  color: #545454;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.32px;
  line-height: 23px;
  text-align: center;
  margin-left: 10px;
  margin-top: 5px;
}

.resultadoCol {
  height: 28px;
  max-width: 60px;
  border-radius: 4px;
  background: linear-gradient(180deg, #b4ec51 0%, #429321 100%);
  padding: 5px;
}

.resultado {
  height: 20px;
  width: 48px;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: -0.89px;
  line-height: 20px;
}

.objConseguido {
  height: 40px;
  width: 124px;
  color: #3c3c3c;
  font-family: "Poppins", sans-serif;
  font-size: 29px;
  font-weight: 600;
  letter-spacing: -1.71px;
  line-height: 40px;
  margin-left: 10px;
}

.objtext {
  height: 15px;
  width: 37px;
  color: #545454;
  font-family: "Poppins", sans-serif;
  font-size: 9px;
  font-weight: 500;
  letter-spacing: -0.2px;
  line-height: 15px;
  text-align: center;
  margin-left: 60px;
}

.objeur {
  height: 23px;
  width: 73px;
  color: #b7b7b7;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 23px;
}

.progress {
  margin: 10px;
}

